import styled from 'styled-components'
import { lg, sm, bodyFont, headingFont, md, sh } from 'config/variables'

export const WorkFlowStyle = styled.section`
  .container{
    ${md(`
      padding: 0;
    `)}
    .heading-wrapper {
      max-width: 682px;
      ${sh(`
        max-width: 610px;
      `)}
      ${md(`
        padding: 0 20px;
      `)}
    }
    .work-flow-listing {
      max-width: 930px;
      margin: 0 auto;
      margin-top: 39px;
      ${md(`
        display: flex;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      `)}
      .work-flow-listing-row {
        display: flex;
        justify-content: space-between;
        ${lg(`
          flex-wrap: wrap;
          flex-direction: column;
          align-content: center;
          align-items: center;
          `)}
        ${md(`
          flex-wrap: nowrap;
          flex-direction: row;
          align-content: center;
          align-items: stretch;
          `)}
        &.reverse {
          flex-direction: row-reverse;
          ${lg(`
            flex-wrap: wrap;
            flex-direction: column;
            align-content: center;
            align-items: center;
            `)}
          ${md(`
            flex-wrap: nowrap;
            flex-direction: row;
            align-content: center;
            align-items: stretch;
            padding-right: 20px;
          `)}
          .single-workflow {
            &:nth-child(1) {
              .arrow-img {
                ${lg(`
                  transform: rotate(180deg)
                  `)}
              }
            }
            &:nth-child(2) {
              .arrow-img {
                ${lg(`
                  transform: rotate(180deg)
                  `)}
              }
            }
            &:last-child {
              .arrow-img {
                ${lg(`
                  display: none;
                  `)}
              }
            }
            .arrow-img {
              bottom: initial;
              top: -33px;
              ${lg(`
                top: initial;
                bottom: -33px;
                @include transform(rotate(180deg));
                `)}
            }
          }
        }
        .single-workflow {
          position: relative;
          max-width: 278px;
          width: 100%;
          padding: 20px;
          &:before {
            content: '';
            height: 10px;
            width: 10px;
            border: 2px solid #dce6fb;
            display: block;
            border-radius: 50%;
            position: absolute;
            top: -7px;
            left: 0;
            right: 0;
            margin: 0px auto;
            background: $white;
            display: none;
            ${lg(`
              display: block;
              `)}
            ${md(`
            display: none;
              `)}
          }
          &:first-child:before {
            display: none;
          }
          ${lg(`
            max-width: 330px;
            margin-bottom: 43px;
            `)}
          ${md(`
            max-width: 278px;
            min-width: 278px;
            margin-left: 20px;
            margin-bottom: 0px;
            `)}
          ${sm(`
            max-width: 250px;
            min-width: 250px;
            `)}
          &.very-light-blue {
            border: 1px solid #dce6fb;
            .workflow-no {
              color: #8d7fd8;
              background: #dce6fb;
            }
            &:before {
              border-color: #dce6fb;
            }
          }
          &.duck-egg-blue {
            border: 1px solid #d1edeb;
            .workflow-no {
              color: #57aea8;
              background: #d1edeb;
            }
            &:before {
              border-color: #d1edeb;
            }
          }
          &.light-peach {
            border: 1px solid #f2dbcb;
            .workflow-no {
              color: #cf8f63;
              background: #f2dbcb;
            }
            &:before {
              border-color: #f2dbcb;
            }
          }
          &.light-green {
            border: 1px solid #d1edeb;
            .workflow-no {
              color: #57aea8;
              background: #d1edeb;
            }
            &:before {
              border-color: #d1edeb;
            }
          }
          &.light-peach-three {
            border: 1px solid #f6e5c4;
            .workflow-no {
              color: #d2a95a;
              background: #f6e5c4;
            }
            &:before {
              border-color: #f6e5c4;
            }
          }
          .workflow-no {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 0;
            right: 0;
            font-size: 18px;
            line-height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: ${headingFont};
            font-weight: 700;
          }
          .img-block {
            width: 50px;
            height: 50px;
            margin-bottom: 5px;
            ${sh(`
              width: 45px;
              height: 45px;
            `)}
            img {
              display: block;
            }
          }
          h6 {
            font-size: 20px;
            line-height: 28px;
            font-family: ${bodyFont};
            margin-bottom: 4px;
            font-weight: 600;
            ${sh(`
              font-size: 18px;
              line-height: 26px;
            `)}
            ${sm(`
              font-size: 20px;
              line-height: 28px;
              `)}
          }
          p {
            font-size: 16px;
            line-height: 22px;
            margin: 0;
            ${sh(`
              font-size: 15px;
              line-height: 21px;
            `)}
            ${sm(`
              font-size: 16px;
              line-height: 26px;
              `)}
          }
          .arrow-img {
            width: 15px;
            height: 39px;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -33px;
            ${md(`
              display: none;
              `)}
          }
        }
      }
      .process-chart {
        margin: 38px 0;
        svg {
          width: 930px;
        }
        ${lg(`
          display: none;
          `)}
      }
    }
  }
`
