import React, { Component } from 'react'
import { TextTestimonialBlock } from './TextTestimonial.styles'
import { images } from 'config/images'
import Picture from 'components/Picture/Picture'

class TextTestimonial extends Component {
  render() {
    return (
      <TextTestimonialBlock>
        <div className="testimonial-bg back-animate" />
        <div className="testimonial-block">
          <img
            src={images.quote}
            alt="Quote"
            className="testimonial-quote-img"
          />
          <div className="client-info">
            <div className="client-img">
              <Picture
                height={50}
                offset={2000}
                srcSet={this.props.clientImg2x}
                src={this.props.clientImg}
                alt=""
              />
            </div>
            <div className="client-basic-info">
              <div className="client-name-rating">
                <div className="client-name">{this.props.clientName}</div>
                <div className="client-rating">
                  <div
                    className="star-active"
                    style={{ width: `${this.props.rating}` }}
                  >
                    <img src={images.starActive} alt="Star" />
                    <img src={images.starActive} alt="Star" />
                    <img src={images.starActive} alt="Star" />
                    <img src={images.starActive} alt="Star" />
                    <img src={images.starActive} alt="Star" />
                  </div>
                  <div className="star-inactive">
                    <img src={images.starInactive} alt="Star" />
                    <img src={images.starInactive} alt="Star" />
                    <img src={images.starInactive} alt="Star" />
                    <img src={images.starInactive} alt="Star" />
                    <img src={images.starInactive} alt="Star" />
                  </div>
                </div>
              </div>
              <div className="client-designation">
                {this.props.clientDesignation}
              </div>
            </div>
          </div>
          <p>{this.props.clientTestimonial}</p>
        </div>
      </TextTestimonialBlock>
    )
  }
}

export default TextTestimonial
