import React, { memo } from 'react'
import ProcessBarSVG from 'components/ProcessBarSVG/ProcessBarSVG'
import { WorkFlowStyle } from './WorkFlow.styles'

const WorkFlow = memo(({ workFlowdata: { workFlowProps } }) => {
  return (
    <WorkFlowStyle className="work-flow-section">
      <div className="container">
        <div className="heading-wrapper">
          <h3>{workFlowProps.heading}</h3>
        </div>
        <div id="demo" className="work-flow-listing">
          <div className="work-flow-listing-row">
            {workFlowProps.workFlowFirstRows.map(workFlowFirstRow => {
              return (
                <div
                  key={workFlowFirstRow.workFlowCount}
                  className={`single-workflow ${workFlowFirstRow.workFlowClass}`}
                >
                  <div className="workflow-no">
                    {workFlowFirstRow.workFlowCount}
                  </div>
                  <div className="img-block">
                    <img
                      src={workFlowFirstRow.workFlowIcon}
                      alt={workFlowFirstRow.workFlowIconAlt}
                    />
                  </div>
                  <h6>{workFlowFirstRow.workFlowTitle}</h6>
                  <p>{workFlowFirstRow.workFlowParagraph}</p>
                  <div className="arrow-img">
                    <img src={workFlowFirstRow.workFlowArrowImg} alt="" />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="process-chart">
            <ProcessBarSVG />
          </div>
          <div className="work-flow-listing-row reverse">
            {workFlowProps.workFlowSecondRows.map(workFlowSecondRow => {
              return (
                <div
                  key={workFlowSecondRow.workFlowCount}
                  className={`single-workflow ${workFlowSecondRow.workFlowClass}`}
                >
                  <div className="workflow-no">
                    {workFlowSecondRow.workFlowCount}
                  </div>
                  <div className="img-block">
                    <img
                      src={workFlowSecondRow.workFlowIcon}
                      alt={workFlowSecondRow.workFlowIconAlt}
                    />
                  </div>
                  <h6>{workFlowSecondRow.workFlowTitle}</h6>
                  <p>{workFlowSecondRow.workFlowParagraph}</p>
                  <div className="arrow-img">
                    <img src={workFlowSecondRow.workFlowArrowImg} alt="" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </WorkFlowStyle>
  )
})

export default WorkFlow
