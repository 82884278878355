import styled from 'styled-components'
import { xl, lg, md, sm, sh } from 'config/variables'

export const CustomrReviewSliderSec = styled.section`
  margin-bottom: 90px;
  ${lg(`
    margin-bottom: 50px;
  `)}
  ${sm(`
    margin-bottom: 80px;
  `)}
  .heading-wrapper {
    max-width: 727px;
  }
  .client-testimonial-list {
    margin: 0 auto;
    .slick-slider {
      margin: 0 auto;
      max-width: 1370px;
      padding: 0 50px;
      ${sh(`
        max-width: 1180px;
        padding: 0 35px;
      `)}
      ${md(`
        padding: 0 40px;
      `)}
      ${sm(`
        padding: 0;
      `)}
      .slick-list {
        padding: 60px 0 70px !important;   
        ${sh(`
          padding: 40px 0 !important;
        `)}
        ${sm(`
          padding: 30px 0 !important;
        `)}
        .slick-track {
          display: flex;
          .slick-slide {
            display: flex;
            height: auto;
            padding: 0 22px;
            ${lg(`
              padding: 0 18px;
            `)}
            ${md(`
              padding: 0 15px;
            `)}
            > div {
              margin: 0 auto;
              width: 100%;
            }
          }
        }
      }
      .slick-arrow {
        width: 32px;
        position: absolute;
        padding: 0;
        top: 50%;
        z-index: 9;
        height: 24px;
        background-color: transparent;
        margin-top: -12px;
        transition: all 0.3s ease;
        outline: none;
        opacity: 1;
        border: 0;
        cursor: pointer;
        svg{
          width: 100%;
        }
        ${sm(`
          width: 25px;
        `)}
        &:hover {
          opacity: 0.8;
        }
        &.slick-prev {
          left: 0;
          ${xl(`
            left: 10px;
          `)}
          ${lg(`
            left: 10px;
          `)}
          ${sm(`
            top: inherit;
            margin: 0;
            bottom: -20px;
            left: calc(50% - 35px);
          `)}
        }
        &.slick-next {
          right: 0;
          ${xl(`
            right: 10px;
          `)}
          ${lg(`
            right: 10px;
          `)}
          ${sm(`
            top: inherit;
            margin: 0;
            bottom: -20px;
            right: calc(50% - 35px);
          `)}
        }
      }
    }
  }
`
