import styled from 'styled-components'
import {
  primaryColor,
  themeColor,
  black,
  white,
  lg,
  md,
  sm,
  sh,
} from 'config/variables'

export const IndustriesServeSec = styled.section`
  .industries-tabbing {
    margin-top: 60px;
    padding: 40px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.1);
    ${sh(`
      margin-top: 34px;
      padding: 36px;
    `)}
    ${md(`
      padding: 40px 30px;
    `)}
    ${sm(`
      padding: 20px 15px;
      margin-top: 20px;
    `)}
    .industries-tab-menu {
      display: flex;
      overflow: auto;
      margin-bottom: -19px;
      padding-bottom: 20px;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar { width: 0 !important }     
      li {
        position: relative;
        text-decoration: none;
        text-align: left;
        cursor: pointer;
        width: 180px;
        min-width: 180px;
        margin-right: 30px;
        padding: 23px 20px 28px;
        border: 1px solid #d1edeb;
        background-color: #f2fffe;
        transition: all 300ms ease;
        list-style: none;
        ${sh(`
          width: 160px;
          min-width: 160px;
          padding: 21px 22px 26px;
          margin-right: 27px;
        `)}
        ${md(`
          font-size: 16px;
          line-height: 22px;
          padding: 15px;
        `)}
        ${sm(`
          margin-right: 15px;
        `)}    
        &.active {
          background-color: #29ded5;
          span {
            color: ${white};
          }
        }
        span {
          display: block;
          transition: all 300ms ease;
          color: ${primaryColor};
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          word-break: break-word;
          ${sh(`
            font-size: 16px;
            line-height: 22px;
          `)}
          ${sm(`
            font-size: 16px;
            line-height: 22px;
          `)}
        }
      }
    }
    .blank-space{
      padding-top: 60px;  
      background: #fff;
      position: relative;
      ${sm(`
        padding-top: 40px;
      `)}
    }
    .industries-tab-content {
      position: relative;
      display: block;      
      background: #fff;     
      .tab-content {
        position: relative;
        display: flex;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: all 0.3s ease;
        &.active {
          position: relative;
          opacity: 1;
        }
        ${lg(`
          flex-wrap: wrap;
        `)}
        .tab-content-text-block {
          width: 100%;
          max-width: 490px;
          h3 {
            margin-top: 0px;
            margin-bottom: 14px;
            color: #222;
            font-size: 30px;
            line-height: 36px;
            font-weight: 500;
            letter-spacing: -0.33px;
            ${sh(`
              font-size: 27px;
              line-height: 33px;
            `)}
            ${md(`
              font-size: 24px;
              line-height: 30px;
            `)}
          }
          p {
            margin-bottom: 0;
            color: ${primaryColor};
            line-height: 30px;
            font-size: 22px;
            ${sh(`
              font-size: 20px;
              line-height: 28px;
            `)}
            ${md(`
              font-size: 18px;
              line-height: 26px;
            `)}
          }
          .button {
            background: transparent;
            border-color: ${themeColor};
            color: ${black};
            font-size: 19px;
            line-height: 27px;
            letter-spacing: -0.16px;
            white-space: nowrap;
            padding: 9px 48px 11px;
            ${sh(`
              font-size: 18px;
              line-height: 26px;
              padding: 9px 43px 11px;
            `)}
            ${md(`
              font-size: 18px;
              line-height: 26px;
              padding: 9px 43px 11px;
            `)}
            &:hover {
              color: ${white};
            }
          }
        }
        .tab-content-img-block {
          display: flex;
          padding-top: 10px;
          ${lg(`
            padding-top: 40px;
          `)}
          ${md(`
            overflow: auto;
          `)}
          .content-single-img-block {
            width: 165px;
            min-width: 165px;
            margin-left: 30px;
            ${lg(`
              margin-left: 0;
              margin-right: 30px;
            `)}
            .tabbing-img {
            }
            .img-block-title {
              color: ${primaryColor};
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
`
