import React from 'react'
import { CustomrReviewSliderSec } from './CustomerReviewSlider.styles'
import TextTestimonial from 'components/TextTestimonial/TextTestimonial'
import VideoTestimonial from 'components/VideoTestimonial/VideoTestimonial'
import loadable from '@loadable/component'
import 'slick-carousel/slick/slick.css'
import { usePreventScroll } from 'components/hooks/usePreventScroll'

const ReactSlick = loadable.lib(() => import('react-slick'))

const settings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  centerPadding: 0,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        centerMode: true,
        variableWidth: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        centerMode: true,
        variableWidth: true,
        centerPadding: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        variableWidth: false,
      },
    },
  ],
}

function SamplePrevArrow(props) {
  const { onClick } = props
  return (
    <button className="slick-arrow slick-prev" onClick={onClick}>
      <svg width="32" height="25" viewBox="0 0 32 25">
        <path
          fill="#3D3D3D"
          fillRule="evenodd"
          d="M0 12a1.22 1.22 0 0 1 0-.13v-.11a1.25 1.25 0 0 1 0-.13l.05-.1.07-.12.07-.09.08-.1L11 .39a1.3 1.3 0 0 1 1.82 0 1.25 1.25 0 0 1 0 1.79l-8.43 8.56h26.32a1.3 1.3 0 0 1 0 2.59H4.29l8.55 8.55a1.25 1.25 0 0 1 0 1.79 1.3 1.3 0 0 1-1.82 0L.37 13l-.08-.09-.08-.09-.06-.11-.06-.11a1.18 1.18 0 0 1 0-.12 1.22 1.22 0 0 1 0-.12 1.16 1.16 0 0 1 0-.12 1.2 1.2 0 0 1 0-.13L0 12z"
        />
      </svg>
    </button>
  )
}

function SampleNextArrow(props) {
  const { onClick } = props
  return (
    <button className="slick-arrow slick-next" onClick={onClick}>
      <svg width="32" height="25" viewBox="0 0 32 25">
        <path
          fill="#3D3D3D"
          fillRule="evenodd"
          d="M32 12a1.22 1.22 0 0 0 0-.13v-.11a1.25 1.25 0 0 0 0-.13l-.05-.1-.07-.12-.07-.09-.08-.1L21 .39a1.3 1.3 0 0 0-1.82 0 1.25 1.25 0 0 0 0 1.79l8.43 8.56H1.29a1.3 1.3 0 0 0 0 2.59h26.42l-8.55 8.55a1.25 1.25 0 0 0 0 1.79 1.3 1.3 0 0 0 1.82 0L31.63 13l.08-.09.08-.09.06-.11.06-.11a1.18 1.18 0 0 0 0-.12 1.22 1.22 0 0 0 0-.12 1.16 1.16 0 0 0 0-.12 1.2 1.2 0 0 0 0-.13L32 12z"
        />
      </svg>
    </button>
  )
}

const CustomerReviewSlider = ({ customReviewSliderdata, data }) => {
  const { customReviewSliderProps } = customReviewSliderdata
  const {
    danielle: {
      childImageSharp: { fluid: danielle },
    },
    jawann: {
      childImageSharp: { fluid: jawann },
    },
    justin: {
      childImageSharp: { fluid: justin },
    },
    olivia: {
      childImageSharp: { fluid: olivia },
    },
    ritwik: {
      childImageSharp: { fluid: ritwik },
    },
    jeff: {
      childImageSharp: { fluid: jeff },
    },
    andy: {
      childImageSharp: { fluid: andy },
    },
  } = data
  let items = [danielle, jawann, justin, olivia, ritwik, jeff, andy]
  usePreventScroll('.client-testimonial-list')
  return (
    <CustomrReviewSliderSec>
      <div className="container">
        <div className="heading-wrapper">
          <h2>{customReviewSliderProps.heading}</h2>
        </div>
      </div>
      <div className="client-testimonial-list">
        <ReactSlick>
          {({ default: Slider }) => (
            <Slider {...settings}>
              {customReviewSliderProps.textTestimonialProps.map(
                (textTestimonialProp, i) => {
                  if (textTestimonialProp.videoURL) {
                    return (
                      <VideoTestimonial
                        key={i}
                        fluid={items[i]}
                        {...textTestimonialProp}
                      />
                    )
                  }
                  return <TextTestimonial key={i} {...textTestimonialProp} />
                }
              )}
            </Slider>
          )}
        </ReactSlick>
      </div>
    </CustomrReviewSliderSec>
  )
}

export default CustomerReviewSlider
