import React, { Component } from 'react'
import { VideoTestimonialBlock } from './VideoTestimonial.styles'
import { images } from 'config/images'
import LightBoxImgVideo from 'components/LightBoxImgVideo/LightBoxImgVideo'
import Image from 'components/ImgOptimized'

class VideoTestimonial extends Component {
  render() {
    return (
      <VideoTestimonialBlock>
        <div className="testimonial-bg back-animate" />
        <div className="testi-video-wrapper">
          <img
            src={images.quote}
            alt="Quote"
            className="testimonial-quote-img"
          />
          <div className="testi-video-padding">
            <div className="video-testi-info">
              <div className="client-info">
                <div className="client-basic-info">
                  <div className="client-name-rating">
                    <div className="client-name">{this.props.clientName}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="testi-video-block">
              <Image
                fluid={this.props.fluid}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 218,
                }}
              />
              <LightBoxImgVideo
                vimeo
                lightboxImgVid={[this.props.videoURL]}
                lightButtonClassName={'play-pause-buttton'}
                buttonText={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M-12-8h36v36h-36z" />
                      <path
                        fill="#FFF"
                        d="M0 2.233v15.534a1.5 1.5 0 0 0 2.305 1.266l12.206-7.768a1.5 1.5 0 0 0 0-2.53L2.305.967A1.5 1.5 0 0 0 0 2.233z"
                      />
                    </g>
                  </svg>
                }
              />
            </div>
            <div className="video-testi-para">
              <p>{this.props.clientTestimonial}</p>
            </div>
          </div>
        </div>
      </VideoTestimonialBlock>
    )
  }
}

export default VideoTestimonial
