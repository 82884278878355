import styled from 'styled-components'
import { themeColor, primaryColor, sm, device, sh, md } from 'config/variables'
import { images } from 'config/images'

export const TextTestimonialBlock = styled.div`
  position: relative;
  transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
  width: 375px;
  margin: 0 auto;
  height: 100%;
  ${sh(`
    width: 330px;
  `)}
  ${sm(`
    width: 95%;
    max-width: 320px;
  `)}
  .testimonial-bg.back-animate {
    position: absolute;
    left: 0px;
    top: 50%;
    right: 0px;
    bottom: auto;
    z-index: -1;
    width: 355px;
    height: 0px;
    margin-right: auto;
    margin-left: auto;
    background-color: #ebe0ff;
    background-position: 0px 0px;
    background-image: url(${images.paternBackground});
    background-size: 24px;
    opacity: 1;
    transform: translate(0px, -50%);
    transition: all 2400ms cubic-bezier(0.2, 0.8, 0.2, 1);
    ${sh(`
      width: 300px;
    `)}
    ${sm(`
      width: 207px;
    `)}
  }
  .testimonial-block {
    position: relative;
    z-index: 1;
    padding: 30px;
    border: 1px solid #d1edeb;
    background-color: #fff;
    box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.06),
      3px 0 5px -1px rgba(0, 0, 0, 0.1);
    transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
    height: 100%;
    ${sh(`
      padding: 24px 27px;
    `)}
    ${md(`
      box-shadow: 1px 0 10px 0 rgba(0,0,0,0.06), 2px 0 3px -1px rgba(0,0,0,0.1);
    `)}
    ${sm(`
      padding: 15px;
    `)}
    .testimonial-quote-img {
      position: absolute;
      left: -9px;
      top: 80px;
      ${sh(`
        width: 32px;
        top: 70px;
      `)}
      ${sm(`
        left: -6px;
        top: 50px;
        width: 25px;
      `)}
    }
    .client-info {
      display: flex;
      padding-bottom: 26px;
      ${sh(`
        padding-bottom: 20px;
      `)}
      .client-img {
        width: 50px;
        height: 50px;
        margin-right: 16px;
        ${sh(`
          width: 45px;
          height: 45px;
          margin-right: 13px;
        `)}
        ${sm(`
          width: 35px;
          height: 35px;
          margin-right: 6px;
        `)}
        img {
          display: block;
        }
      }
      .client-basic-info {
        .client-name-rating {
          display: flex;
          margin-bottom: 3px;
          align-items: center;
          .client-name {
            margin-right: 10px;
            color: ${themeColor};
            font-size: 16px;
            line-height: 22px;
            ${sh(`
              font-size: 15px;
              line-height: 21px;
            `)}
            ${sm(`
              font-size: 14px;
              line-height: 14px;
            `)}
          }
          .client-rating {
            position: relative;
            width: 100px;
            height: 15px;
            padding-right: 1px;
            padding-bottom: 0px;
            ${sm(`
              display: none;
            `)}
            .star-active {
              position: absolute;
              left: 0%;
              top: 0%;
              right: auto;
              bottom: auto;
              z-index: 2;
              display: flex;
              overflow: hidden;
              width: 80%;
              height: 100%;
              img {
                margin: 0 2px;
                ${sm(`
                  width: 9px;
                `)}
              }
            }
            .star-inactive {
              position: absolute;
              left: 0%;
              top: 0%;
              right: auto;
              bottom: auto;
              z-index: 1;
              display: flex;
              width: 100%;
              height: 100%;
              img {
                margin: 0 2px;
                ${sm(`
                  width: 9px;
                `)}
              }
            }
          }
        }
        .client-designation {
          color: ${primaryColor};
          font-size: 13px;
          line-height: 18px;
          font-weight: 500;
          text-transform: uppercase;
          ${sh(`
            font-size: 12px;
            line-height: 16px;
          `)}
          ${sm(`
            font-size: 12px;
            line-height: 18px;
          `)}
        }
      }
    }
    p {
      font-size: 18px;
      line-height: 24px;
      margin: 0;
      ${sh(`
        font-size: 16px;
        line-height: 24px;
      `)}
      ${sm(`
        font-size: 16px;
        line-height: 24px;
      `)}
    }
  }
  &:hover {
    transform: translate(0px, 15px);
    ${device(`
      transform: initial;
    `)}
    .testimonial-bg {
      height: calc(100% + 30px);
      transition: all 400ms cubic-bezier(0.2, 0.8, 0.2, 1);
      ${device(`
        display: none;
      `)}
    }
    .testimonial-block {
      background: #f6f9ff;
      box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1),
      0 9px 20px 8px rgba(0, 0, 0, 0.06), 0 15px 20px 3px rgba(0, 0, 0, 0.07);
      ${sh(`
        box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1), 0 4px 10px 5px rgba(0,0,0,0.06), 0 14px 20px 2px rgba(0,0,0,0.07);
      `)}
      ${device(`
        box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.06), 3px 0 5px -1px rgba(0, 0, 0, 0.1);
      `)}
    }
  }
`
