import styled from 'styled-components'
import {
  themeColor,
  white,
  primaryColor,
  sm,
  device,
  sh,
  md,
} from 'config/variables'
import { images } from 'config/images'

export const VideoTestimonialBlock = styled.div`
  position: relative;
  transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
  width: 375px;
  margin: 0 auto;
  ${sh(`
    width: 330px;
  `)}
  ${sm(`
    width: 95%;
    max-width: 320px;
  `)}
  .testimonial-bg.back-animate {
    position: absolute;
    left: 0px;
    top: 50%;
    right: 0px;
    bottom: auto;
    z-index: -1;
    width: 355px;
    height: 0px;
    margin-right: auto;
    margin-left: auto;
    background-color: #ebe0ff;
    background-position: 0px 0px;
    background-image: url(${images.paternBackground});
    background-size: 24px;
    opacity: 1;
    transform: translate(0px, -50%);
    transition: all 2400ms cubic-bezier(0.2, 0.8, 0.2, 1);
    ${sh(`
      width: 300px;
    `)}
    ${sm(`
      width: 207px;
    `)}
  }
  .testi-video-wrapper {
    box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.06),
      3px 0 5px -1px rgba(0, 0, 0, 0.1);
    transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
    padding-bottom: 0;
    background-color: #fff;
    ${md(`
      box-shadow: 1px 0 10px 0 rgba(0,0,0,0.06), 2px 0 3px -1px rgba(0,0,0,0.1);
    `)}
    .testimonial-quote-img {
      position: absolute;
      left: -9px;
      bottom: 65px;
      z-index: 9;
      transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
      ${sh(`
        width: 32px;
      `)}
      ${sm(`
        left: -5px;
        width: 25px;
        bottom: 80px;
      `)}
    }
    .testi-video-padding {
      overflow: hidden;
      position: relative;
      padding-bottom: 80px;
      ${sm(`
        padding-bottom: 90px;
      `)}
      .client-info {
        position: absolute;
        left: 0%;
        top: 0%;
        right: auto;
        bottom: auto;
        z-index: 9;
        width: 100%;
        padding: 8px;
        padding-left: 15px;
        background-image: linear-gradient(180deg, #000, transparent);
        display: flex;
        .client-img {
          width: 36px;
          height: 36px;
          margin-right: 10px;
          ${sm(`
            width: 30px;
            height: 30px;
            margin-right: 8px;
          `)}
          img {
            display: block;
          }
        }
        .client-basic-info {
          flex: 1;
          .client-name-rating {
            display: flex;
            margin-bottom: 3px;
            align-items: center;
            ${sm(`
              justify-content: space-between;
            `)}
            .client-name {
              margin-right: 10px;
              color: ${white};
              font-size: 16px;
              line-height: 22px;
              font-weight: 500;
              ${sm(`
                font-size: 14px;
                line-height: 14px;
              `)}
            }
            .client-rating {
              position: relative;
              width: 100px;
              height: 15px;
              padding-right: 1px;
              padding-bottom: 0px;
              ${sm(`
                display: none;
              `)}
              .star-active {
                position: absolute;
                left: 0%;
                top: 0%;
                right: auto;
                bottom: auto;
                z-index: 2;
                display: flex;
                overflow: hidden;
                width: 80%;
                height: 100%;
                img {
                  margin: 0 2px;
                  ${sm(`
                    width: 9px;
                  `)}
                }
              }
              .star-inactive {
                position: absolute;
                left: 0%;
                top: 0%;
                right: auto;
                bottom: auto;
                z-index: 1;
                display: flex;
                width: 100%;
                height: 100%;
                img {
                  margin: 0 2px;
                  ${sm(`
                    width: 9px;
                  `)}
                }
              }
            }
          }
          .client-designation {
            color: ${white};
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            text-transform: uppercase;
            ${sm(`
              font-size: 12px;
              line-height: 11px;
            `)}
          }
        }
      }
      .testi-video-block {
        width: 100%;
        position: relative;
        img {
          width: 100%;
        }
        .play-pause-buttton {
          width: 50px;
          height: 50px;
          position: absolute;
          margin: 0 auto;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: ${themeColor};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          outline: none;
          box-shadow: inherit;
          cursor: pointer;
          transition: all 0.5s ease-in-out;
          ${sh(`
            width: 50px;
            height: 50px;
            bottom: 22px;
          `)}
          ${sm(`
            width: 45px;
            height: 45px;
            bottom: 15px;
          `)}
          svg {
            width: 12px;
            margin-right: -4px;
            ${sh(`
            width: 14px;
          `)}
            ${sm(`
            width: 19px;
          `)}
          path + path {
              transition: all 0.5s ease-in-out;
            }
          }
          &:hover {
            background: ${white};
            svg {
              path + path {
                fill: ${themeColor};
              }
            }
          }
        }
      }
      .video-testi-para {
        padding: 10px 20px 12px 30px;
        transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
        background: #fff;
        position: absolute;
        bottom: 0;
        height: 80px;
        left: 0;
        ${sh(`
        `)}
        ${sm(`
          padding: 8px 16px;
          height: 90px;
        `)}
        p {
          color: ${primaryColor};
          font-size: 18px;
          line-height: 28px;
          margin: 0;
          ${sh(`
            font-size: 16px;
            line-height: 25px;
          `)}
          ${sm(`
            font-size: 16px;
            line-height: 24px;
          `)}
        }
      }
    }
  }
  &:hover {
    transform: translate(0px, 15px);
    ${device(`
      transform: initial;
    `)}
    .testimonial-bg {
      height: calc(100% + 30px);
      transition: all 400ms cubic-bezier(0.2, 0.8, 0.2, 1);
      ${device(`
        display: none;
      `)}
    }
    .testi-video-wrapper {
      box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.1),
        0 9px 20px 8px rgba(0, 0, 0, 0.06), 0 15px 20px 3px rgba(0, 0, 0, 0.07);
      background: #f6f9ff;
      ${sh(`
        box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1), 0 4px 10px 5px rgba(0,0,0,0.06), 0 14px 20px 2px rgba(0,0,0,0.07);
      `)}
      ${device(`
        box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.06), 3px 0 5px -1px rgba(0, 0, 0, 0.1);
      `)}
      .testi-video-padding {
        .video-testi-para {
          bottom: 0;
          background: #f6f9ff;
          ${device(`
            box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.06), 3px 0 5px -1px rgba(0, 0, 0, 0.1);
          `)}
        }
      }
    }
  }
`
