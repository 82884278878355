import React, { Component } from 'react'

class ProcessBarSVG extends Component {
  render() {
    return (
      <svg width="944" height="115" viewBox="0 0 944 115" version="1.1">
        <g id="Rectangle" transform="translate(8.000000, 8.000000)">
          <path
            d="M59,1h828c30.9,0,56,25.1,56,56v0c0,30.9-25.1,56-56,56H59C28.1,113,3,87.9,3,57v0C3,26.1,28.1,1,59,1z"
            id="theMotionPath"
            fill="#fff"
            fillRule="nonzero"
            stroke="#DCE6FB0"
            strole-width="2"
          />
        </g>
        <g id="Rectangle1" transform="translate(0.000000, 0.000000)">
          <path
            d="M886.4,114.6H57.6c-31.5,0-57-26.6-57-56.5l0,0c0-29.9,25.5-57.5,57-57.5h828.9c31.5,0,57,27.6,57,57.5l0,0
	C943.4,87.9,918,114.6,886.4,114.6z"
            id="theMotionPath1"
            fill="#fff"
            fillRule="nonzero"
            stroke="#DCE6FB0"
            strole-width="2"
          />
        </g>
        <g id="Rectan" transform="translate(0.000000, 0.000000)">
          <path
            d="M879.9,107.1H64.1c-31.5,0-57-19.1-57-49l0,0c0-29.9,25.5-50,57-50h815.9c31.5,0,57,20.1,57,50l0,0  C936.9,87.9,911.5,107.1,879.9,107.1z"
            id="theMotionPath1"
            fill="#fff"
            fillRule="nonzero"
            stroke="#BECCEA"
            strole-width="2"
          />
        </g>
        <circle
          cx="471.5"
          cy="7.5"
          r="6.5"
          fill="#FFF"
          stroke="#D3EEEC"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          cx="141.5"
          cy="7.5"
          r="6.5"
          fill="#FFF"
          stroke="#DCE6FB"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          cx="801.5"
          cy="7.5"
          r="6.5"
          fill="#FFF"
          stroke="#F2DDCE"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          cx="141.5"
          cy="107.5"
          r="6.5"
          fill="#FFF"
          stroke="#F6E5C5"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          cx="471.5"
          cy="107.5"
          r="6.5"
          fill="#FFF"
          stroke="#DCE6FB"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          cx="801.5"
          cy="107.5"
          r="6.5"
          fill="#FFF"
          stroke="#D3EEEC"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <g
          id="plane1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Home"
            transform="translate(-548.000000, -10940.000000)"
            fill="#DCE6FB"
            fillRule="nonzero"
          >
            <g id="process" transform="translate(0.000000, 10548.000000)">
              <g id="Group-17" transform="translate(247.000000, 145.000000)">
                <g
                  id="Process_linebar"
                  transform="translate(0.000000, 7.000000)"
                >
                  <path
                    d="M301.839209,248.888889 L301.839209,246.888889 L301.839209,241.724004 C301.839209,241.262783 302.213103,240.888889 302.674325,240.888889 C302.803972,240.888889 302.931839,240.919074 303.0478,240.977054 L315.377386,247.141848 C315.789915,247.348112 315.957126,247.849743 315.750861,248.262272 C315.670052,248.42389 315.539004,248.554938 315.377386,248.635747 L303.0478,254.80054 C302.635271,255.006805 302.133639,254.839595 301.927375,254.427066 C301.869395,254.311105 301.839209,254.183238 301.839209,254.053591 L301.839209,248.888889 Z"
                    id="Line"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <animateMotion
          xlinkHref="#plane"
          dur="30s"
          repeatCount="indefinite"
          rotate="auto"
        >
          <mpath xlinkHref="#theMotionPath" />
        </animateMotion>

        <g
          id="plane"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Home1"
            transform="translate(-548.000000, -10940.000000)"
            fill="#DCE6FB"
            fillRule="nonzero"
          >
            <g id="process1" transform="translate(0.000000, 10548.000000)">
              <g id="Group-171" transform="translate(247.000000, 145.000000)">
                <g
                  id="Process_linebar1"
                  transform="translate(0.000000, 7.000000)"
                >
                  <path
                    d="M301.839209,248.888889 L301.839209,246.888889 L301.839209,241.724004 C301.839209,241.262783 302.213103,240.888889 302.674325,240.888889 C302.803972,240.888889 302.931839,240.919074 303.0478,240.977054 L315.377386,247.141848 C315.789915,247.348112 315.957126,247.849743 315.750861,248.262272 C315.670052,248.42389 315.539004,248.554938 315.377386,248.635747 L303.0478,254.80054 C302.635271,255.006805 302.133639,254.839595 301.927375,254.427066 C301.869395,254.311105 301.839209,254.183238 301.839209,254.053591 L301.839209,248.888889 Z"
                    id="Line"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <animateMotion
          xlinkHref="#plane1"
          dur="30s"
          repeatCount="indefinite"
          rotate="auto"
        >
          <mpath xlinkHref="#theMotionPath1" />
        </animateMotion>
      </svg>
    )
  }
}

export default ProcessBarSVG
