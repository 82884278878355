import React, { useState } from 'react'
import { IndustriesServeSec } from './IndustriesServe.styles'
import Picture from 'components/Picture/Picture'
import { useDraggable } from 'components/hooks/useDraggable'

function IndustriesServe({ industriesServedata: { industriesServeProps } }) {
  const [tabIndex, setTabIndex] = useState(0)
  useDraggable('.industries-tab-menu')

  return (
    <IndustriesServeSec className="industries-service">
      <div className="container">
        <div className="heading-wrapper">
          <h2>{industriesServeProps.heading}</h2>
        </div>
        <div className="industries-tabbing">
          <ul className="industries-tab-menu">
            {industriesServeProps.tabContents.map(({ tabName }, i) => {
              return (
                <li
                  key={i}
                  onClick={() => setTabIndex(i)}
                  className={tabIndex === i ? 'active' : ''}
                >
                  <span>{tabName}</span>
                </li>
              )
            })}
          </ul>
          <div className="blank-space"></div>
          <div className="industries-tab-content">
            {industriesServeProps.tabContents.map(
              (
                {
                  contentHeading,
                  contentPara,
                  contentSingleProps,
                  buttonName,
                  buttonURL,
                },
                i
              ) => {
                return (
                  <div
                    key={i}
                    className={`tab-content ${tabIndex === i ? 'active' : ''}`}
                  >
                    <div className="tab-content-text-block">
                      <h3>{contentHeading}</h3>
                      <p>{contentPara}</p>
                    </div>
                    <div className="tab-content-img-block">
                      {contentSingleProps.map((contentSingleProps, i) => {
                        return (
                          <div
                            key={`${i}${i}`}
                            className="content-single-img-block"
                          >
                            <Picture
                              height={165}
                              offset={0}
                              srcSet={contentSingleProps.singleblkImg2x}
                              src={contentSingleProps.singleblkImg}
                              alt=""
                              className="tabbing-img"
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </div>
    </IndustriesServeSec>
  )
}

export default IndustriesServe
