import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const DigitalProductEngServicesProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Transform and reach your true business potential with Simform’s new-age{' '}
        <span className="heading-red">
          digital product engineering services
        </span>
      </>
    ),
    bannerContent:
      'We assist you in innovating and making digital technology work for you perennially. Our team of digital experts assists you with human-centric design, development, testing, and consulting to help you transform everyday experiences.',
    BannerAlt: 'Digital Product Engineering Services',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Why Simform for{' '}
        <span className="highlight-head">digital engineering services?</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            Simform’s human-centric digital product engineering approach with
            the best industry expertise and experience support you create
            products that empower users, deliver business value and make a
            societal impact. <br /> <br />
            Our team is proficient in conceptualizing, designing, prototyping,
            developing, and delivering new digital products. Our process starts
            with understanding end users’ requirements, followed by leveraging
            technology in a manner that meets those needs innovatively. <br />
            <br />
            Some of our uniquely designed services include product & platform
            strategy, product & platform engineering, product modernization, and
            product maintenance & support. Simform has assisted leading software
            brands in winning the war on scalability, performance, and
            usability. When it comes to digital product engineering, Simform
            helps you stay relevant.
          </>
        ),
      },
      {
        detailsList: [
          'Human-centric design methodology',
          'Product development expertise',
          'Transparent & delivery-oriented culture',
          'Agile & scalable teams',
          'Automation expertise',
          'Rigorous QA & testing',
          'AI/ML expertise',
          'Certified data engineers',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our Product Engineering <span className="highlight-head">Services</span>
      </>
    ),
    servicesPara:
      'The best software solutions are just one step away now with Simform’s digital product engineering services! Explore our range of service offerings and select the best for your needs.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'Product Strategy',
        className: 'web-application',
        mainPara: (
          <>
            Simform's product strategy services help you define and refine your
            product vision, ensuring market fit and long-term success. We work
            closely with you to create a roadmap that aligns with your business
            goals and user needs.
          </>
        ),
        listItem: [
          {
            title: 'Focus workshops',
            para:
              'Gain deep insights into your target market, competitors, and industry trends. Our team collaborates with you to conduct thorough research to identify opportunities and potential challenges for your product.',
          },
          {
            title: 'Product roadmap development',
            para: (
              <>
                Get a clear, actionable roadmap that outlines your product's
                evolution. We help prioritize features, set milestones, and
                define key performance indicators (KPIs) to measure success.
              </>
            ),
          },
          {
            title: 'Distribution and adoption planning',
            para:
              'We devise strategies to ensure that your product not only reaches the intended users but is also successfully adopted across different channels.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/project-strategy/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Product Engineering and Development',
        className: 'custom-software',
        mainPara: (
          <>
            Simform's product engineering and development offerings are designed
            to cater to the unique requirements of each client. From rapid
            prototyping to iterative development, we help you validate your idea
            and make it a reality.
          </>
        ),
        listItem: [
          {
            title: 'Product Discovery and Design',
            para:
              'Replace guesswork with product discovery. We create a strategic canvas and work with you to understand user flows and refine your ideas by deeply understanding real user problems and jobs to be done.',
          },
          {
            title: 'Rapid Prototype Building',
            para:
              'Rapidly validate your ideas with our prototyping team that assists you in product design and functionality, minimizes failure risks, and tests the product in your target customer segment.',
          },
          {
            title: 'UI Design and UI Development',
            para:
              'Use data to understand what customers do and why they do it. Intuitive user flows are created that make users’ jobs easier. Our creative designers build interactions to bring the vision to life.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/software-product-development/',
      },
      {
        heading: 'Performance Engineering and Testing',
        className: 'software-testing',
        mainPara: (
          <>
            Our service portfolio offers a full spectrum of world-class
            performance engineering services. We employ a dual-shift approach to
            help you plan capacity proactively for increased ROI and faster
            delivery.
          </>
        ),
        listItem: [
          {
            title: 'Continuous Performance Testing',
            para:
              'Troubleshoot performance issues during development rather than later when an application is in production. With Simform, you can know - immediately and precisely - the kind of impact each change has on your app’s performance.',
          },
          {
            title: 'Capacity Planning',
            para:
              'Eliminate capacity inefficiency and say goodbye to under-utilized resources or dissatisfied customers. Introduce tried-and-tested techniques and processes to minimize capacity planning discrepancies with Simform’s proactive performance engineering approach.',
          },
          {
            title: 'Shift-left Testing',
            para:
              'Leverage the shift-left testing approach to reduce the number of bugs and increase the code quality. Regularly testing code with each code increment guarantees the project’s quality.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/performance-engineering/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Application Modernization',
        className: 'enterprise-mobile',
        mainPara: (
          <>
            Simform's application modernization experts enable IT leaders to
            create a custom roadmap and help migrate to modern infrastructure
            using cloud technologies to generate better ROI and reduce cloud
            expenditure.
          </>
        ),
        listItem: [
          {
            title: 'Application Assessment',
            para:
              'Simform helps you access your current application infrastructure and provide you with an accurate understanding of your legacy systems. This enables teams to make better decisions while modernizing the apps.',
          },
          {
            title: 'End-to-end Migration',
            para: (
              <>
                Our application migration experience helps you shift workloads
                from one cloud to another with minimum disruption. Whether
                you're rehosting, refactoring, or re-platforming, Simform has
                got you covered.
              </>
            ),
          },
          {
            title: 'Application Rearchitecture',
            para: (
              <>
                With Simform's cloud architects, rearchitect your application
                and migrate your legacy logic and code to a new platform in a
                way that best serves your business capabilities.
              </>
            ),
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/application-modernization/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'QA Engineering',
        className: 'api-integration',
        mainPara: (
          <>
            We ensure that your product meets the highest standards of quality,
            reliability, and user satisfaction. Our seasoned quality assurance
            engineers use best practices like early and frequent testing,
            proactive planning, and latest technology to ensure high-quality of
            your product.
          </>
        ),
        listItem: [
          {
            title: 'Automated testing implementation',
            para:
              'Leverage  automation to improve test coverage and efficiency. We implement automated testing solutions that integrate seamlessly with your development pipeline, ensuring rapid feedback and continuous quality assurance.',
          },
          {
            title: 'Performance testing',
            para: (
              <>
                Evaluate application stability, responsiveness, and scalability.
                Our comprehensive services, including load testing, stress
                handling, and endurance checks, give you a detailed analysis of
                the results to ensure optimal performance.
              </>
            ),
          },
          {
            title: 'Security and compliance testing',
            para: (
              <>
                Protect your users and business with thorough security testing.
                Our team identifies vulnerabilities, ensures compliance with
                industry standards, and provides recommendations to improve your
                product's security posture.
              </>
            ),
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/software-testing/',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working With
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    processPara:
      'There is no one-size, one-idea-fits-all kind of solution. At Simform, our software engineers are versatile and constantly improve on their skillset who can adapt and evolve according to the requirement of your application development.',
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Eminent expertise',
        workingBenefitsContent: (
          <>
            Our expert development team dedicates 100% of their time to the
            assigned client. They also nurture long-term relationships and gain
            in-depth knowledge of their business domain.
          </>
        ),
        benefitImgAlt: 'Eminent expertise',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Intrinsically agile',
        workingBenefitsContent: (
          <>
            Our strong pillar is that we follow adaptive planning in an agile
            approach to build apps flexibly. This helps us reduce repetition in
            work and empower cross-functional team collaboration to deliver
            projects on time.
          </>
        ),
        benefitImgAlt: 'Intrinsically agile',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'End-to-end services',
        workingBenefitsContent: (
          <>
            We have a proven record of satisfied clients. Be its cloud
            solutions, development or mobile, web or desktop apps, creation of
            custom, enterprise, or SaaS apps. We have a team ready for your
            every need.
          </>
        ),
        benefitImgAlt: 'End-to-end services',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Scalable team',
        workingBenefitsContent: (
          <>
            Build a distributed team filled with the right talent that can meet
            different market demands. Incorporate additional developers who can
            expand your existing team and add value to your project.
          </>
        ),
        benefitImgAlt: 'Scalable team',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Unmatched quality',
        workingBenefitsContent: (
          <>
            Beyond development, we take it seriously to make sure the deployed
            product is compliant with set standards for security, performance,
            dependencies, and so much more. We make room for constant
            improvement.
          </>
        ),
        benefitImgAlt: 'Unmatched quality',
      },
    ],
  },
  customDevProps: {
    serviceHeading: (
      <>
        Software Development in <br />
        <span className="highlight-head">Cutting-Edge Technologies</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Big Data',
        serviceParagraph: (
          <>
            Handle enormous amounts of data and limitless tasks virtually using
            cloud service-compatible frameworks like MongoDB, Hadoop, etc.
          </>
        ),
        serviceIcon: images.scloudApp,
        serviceAlt: 'Mobile',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Internet of Things (IoT)',
        serviceParagraph: (
          <>
            Scale your application smartly. Ranging from middleware to
            architecture creation, our team builds sleek IoT interfaces for all
            MVP.
          </>
        ),
        serviceIcon: images.sInternetofThings,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Artificial Intelligence (AI)',
        serviceParagraph: (
          <>
            Harness of the power of automation. We create AI capabilities that
            are reliable and interpretable with your software architecture.
          </>
        ),
        serviceIcon: images.sartificialIntelligence,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'VR and AR app Development',
        serviceParagraph: (
          <>
            Leverage the power of VR and AR. Tap into our WebVR, photogrammetry,
            and Storyboarding skills in development.
          </>
        ),
        serviceIcon: images.svrArAppDev,
        serviceAlt: 'Software',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: 'Machine Learning (ML)',
        serviceParagraph: (
          <>
            Develop your app intelligently. Build, train, and deploy ML models
            for various use cases of your application as desired.
          </>
        ),
        serviceIcon: images.smachineLearning,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Blockchain',
        serviceParagraph: (
          <>
            Create DApps, build multi-blockchain compatible features, smart
            contracts with cryptocurrency solutions, and integrate REST APIs.
          </>
        ),
        serviceIcon: images.sblockchain,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Custom Software</span>
        <br />
        Development Case Studies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">Customers Say</span>
        <br />
        About Our Services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  workFlowProps: {
    heading: (
      <>
        Software Development <br />
        <span className="highlight-head">Process</span>
      </>
    ),
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.iconTeam,
        workFlowIconAlt: 'Choosing the team',
        workFlowTitle: 'Choosing the team',
        workFlowParagraph:
          'We pick a talent pool with the right team, the right expertise, domain knowledge, and willingness to adapt and evolve to your project environment.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.iconSprint,
        workFlowIconAlt: 'Development methodology planning',
        workFlowTitle: 'Development methodology planning',
        workFlowParagraph:
          'Choose the suitable methodology that empowers the development process to be conducted at its best. Our team members follow sprint planning and agile techniques.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.iconTech,
        workFlowIconAlt: 'Architecture creation',
        workFlowTitle: 'Architecture creation',
        workFlowParagraph:
          'We handle all architecture evolution requirements ranging from UI/UX enhancement, decoupling tightly packed features by migrating to microservices or modularizing the existing architecture.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
    ],
    workFlowSecondRows: [
      {
        workFlowClass: 'light-green',
        workFlowCount: '4',
        workFlowIcon: images.iconStandUps,
        workFlowIconAlt: 'Project Delivery',
        workFlowTitle: 'Project Delivery',
        workFlowParagraph:
          'Beyond development, we set various endpoints where at each point, we deploy the completed project in several phases to reduce reiteration and ensure smooth deployment promptly as promised.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '5',
        workFlowIcon: images.iconCodeReviews,
        workFlowIconAlt: 'Intelligent Testing',
        workFlowTitle: 'Intelligent Testing',
        workFlowParagraph:
          'We create useful test cases catered to identify septic storage problems, memory leaks, and security vulnerabilities. We used a variety of testing technologies and tools that best fit your project.',
        workFlowArrowImg: images.iconFlowArrow5,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '6',
        workFlowIcon: images.iconIterate,
        workFlowIconAlt: 'Periodical reviews',
        workFlowTitle: 'Periodical reviews',
        workFlowParagraph:
          'We conduct timely reviews to squash any foreseeable concern,  present weekly demos, and standups to address any existing bottlenecks to ensure everyone is on the same page.',
        workFlowArrowImg: images.iconFlowArrow6,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        Industries <span className="highlight-head">We Serve</span>
      </>
    ),
    tabContents: [
      {
        tabName: 'Travel',
        contentHeading: 'Rent-a-Car Mobile App',
        contentPara:
          'Create a multilingual application that supports reviews, ratings, photos, 24/7 support with multi-payment gateway features to make the process of travel app services smooth and secured.',
        contentSingleProps: [
          {
            singleblkImg: images.mobilebus1,
            singleblkImg2x: images.mobilebus12x,
          },
          {
            singleblkImg: images.mobilebus2,
            singleblkImg2x: images.mobilebus22x,
          },
          {
            singleblkImg: images.mobilebus3,
            singleblkImg2x: images.mobilebus32x,
          },
        ],
      },
      {
        tabName: 'Finance',
        contentHeading: 'Mobile app for budgeting',
        contentPara:
          'Provide users with capabilities to calculate balances, manage personal investments, features to transfer money, and plan weekly/month budgets. We integrate advanced security APIs to ensure there is no breach.',
        contentSingleProps: [
          {
            singleblkImg: images.mobbanking1,
            singleblkImg2x: images.mobbanking12x,
          },
          {
            singleblkImg: images.mobbanking2,
            singleblkImg2x: images.mobbanking22x,
          },
          {
            singleblkImg: images.mobbanking3,
            singleblkImg2x: images.mobbanking32x,
          },
        ],
      },
      {
        tabName: 'Media & Entertainment',
        contentHeading: 'On-demand streaming Mobile/Web App',
        contentPara:
          'Attract users to your steaming platform with advanced analytics powered by AI, ML, and cloud power to create live-stream and online streaming from servers along with features of a recommendation engine, sharing, and tracking of user behavior.',
        contentSingleProps: [
          {
            singleblkImg: images.mediastream1,
            singleblkImg2x: images.mediastream12x,
          },
          {
            singleblkImg: images.mediastream2,
            singleblkImg2x: images.mediastream22x,
          },
          {
            singleblkImg: images.mediastream3,
            singleblkImg2x: images.mediastream32x,
          },
        ],
      },
      {
        tabName: 'Software',
        contentHeading: 'Warehouse Management Application',
        contentPara:
          'Build a custom SaaS application to manage inventory, stock-in, stock-out, etc., and serve as a central point for managing all B2B operations of the business. We can also create such Paas or APIaaS.',
        contentSingleProps: [
          {
            singleblkImg: images.b2b1,
            singleblkImg2x: images.b2b12x,
          },
          {
            singleblkImg: images.b2b2,
            singleblkImg2x: images.b2b22x,
          },
          {
            singleblkImg: images.b2b3,
            singleblkImg2x: images.b2b32x,
          },
        ],
      },
      {
        tabName: 'Healthcare',
        contentHeading: 'eReporting Mobile App',
        contentPara:
          'Leverage the power of the cloud to build a data management application where all patient reports are stored online for easier and quicker access. We create features for data storing, indexing, cache management, and dependency handling.',
        contentSingleProps: [
          {
            singleblkImg: images.doctor1,
            singleblkImg2x: images.doctor12x,
          },
          {
            singleblkImg: images.doctor2,
            singleblkImg2x: images.doctor22x,
          },
          {
            singleblkImg: images.doctor3,
            singleblkImg2x: images.doctor32x,
          },
        ],
      },
      {
        tabName: 'NGO',
        contentHeading: 'Public Welfare App',
        contentPara:
          'Development of a mobile application that stores necessary demographic detail of the user, which can be correlated against healthcare and food industry to serve the underprivileged in real-time. Predictive analysis through data science programs written for ML model is used in large here.',
        contentSingleProps: [
          {
            singleblkImg: images.publich1,
            singleblkImg2x: images.publich12x,
          },
          {
            singleblkImg: images.publich2,
            singleblkImg2x: images.publich22x,
          },
          {
            singleblkImg: images.publich3,
            singleblkImg2x: images.publich32x,
          },
        ],
      },
      {
        tabName: 'Education',
        contentHeading: 'Virtual Classroom App',
        contentPara:
          'Build a mobile and web app where CMS and CDN are leveraged to deliver content at its best to the students virtually. The app environment is also created with high data management tools to ensure easy access and designed in a way to maintain smooth navigation.',
        contentSingleProps: [
          {
            singleblkImg: images.educationElearning1,
            singleblkImg2x: images.educationElearning12x,
          },
          {
            singleblkImg: images.educationElearning2,
            singleblkImg2x: images.educationElearning22x,
          },
          {
            singleblkImg: images.educationElearning3,
            singleblkImg2x: images.educationElearning32x,
          },
        ],
      },
      {
        tabName: 'Retail breaking down',
        contentHeading: 'Social Marketplace App',
        contentPara:
          'Develop an app where sellers and purchasers can interact without any middleman to buy and rent food trucks and land spaces. Usage of microservices plays a role in breaking down multiple large operations into smaller services decoupling all tasks.',
        contentSingleProps: [
          {
            singleblkImg: images.mobecomm1,
            singleblkImg2x: images.mobecomm12x,
          },
          {
            singleblkImg: images.mobecomm2,
            singleblkImg2x: images.mobecomm22x,
          },
          {
            singleblkImg: images.mobecomm3,
            singleblkImg2x: images.mobecomm32x,
          },
        ],
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/build-ecommerce-progressive-web-app-react/',
        featurecardTitle: 'How To Build an eCommerce PWA using React',
      },
      {
        blogLink:
          'https://www.simform.com/blog/make-gps-vehicle-fleet-tracking-system-hardware/',
        featurecardTitle:
          'How to Make a GPS Tracking System for Vehicle and Fleet Tracking',
      },
      {
        blogLink:
          'https://www.simform.com/blog/cost-to-build-a-saas-application/',
        featurecardTitle:
          'How Much Does it Cost to Build a SaaS Application? (In-Depth Guide)',
      },
    ],
  },
}
